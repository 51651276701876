import styles from "./ContactUs.module.css";
import { useRequestSong } from "../../context/RequestOrderContext";

function ContactUs() {
  const { contactUs, handleChange } = useRequestSong();
  function handleSubmit(e) {
    e.preventDefault();
    contactUs();
  }
  return (
    <div className={styles.contactWrap}>
      <h1>Partner with us</h1>
      <form className={styles.contactForm} onSubmit={handleSubmit}>
        <input onChange={handleChange} name="email" type="email" placeholder="Your Email..." />
        <input
          onChange={handleChange}
          name="business_name"
          type="text"
          placeholder="Business Name..."
        />
        <textarea
          onChange={handleChange}
          name="message"
          placeholder="Tell us about your business..."
        ></textarea>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default ContactUs;
