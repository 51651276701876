import styles from "./HowItWorks.module.css";
import HowItWorks1 from "../../images/how-it-works-1.PNG";
import HowItWorks2 from "../../images/how-it-works-2.PNG";
import HowItWorks3 from "../../images/how-it-works-3.PNG";
import HowItWorks4 from "../../images/how-it-works-4.PNG";

function HowItWorks() {
  return (
    <div className={styles.howItWorksWrap}>
      <h1>How it works</h1>
      <div className={styles.images}>
        <img src={HowItWorks2} alt="how it works" />
        <img src={HowItWorks3} alt="how it works" />
        <img src={HowItWorks4} alt="how it works" />
        <img src={HowItWorks1} alt="how it works" />
      </div>
      <div className={styles.points}>
        <div>
          <div className={styles.line}></div>
          <div className={styles.textBox}>
            <div className={styles.heading}>
              <div id={styles.num}>{1}</div>
              <h3>From the Top</h3>
            </div>
            <p>Who would you like to have a custom song created for or dedicated to?</p>
          </div>
          <div className={styles.textBox}>
            <div className={styles.heading}>
              <div id={styles.num}>{2}</div>
              <h3>Select the perfect artist</h3>
            </div>
            <p>
              Do you have a specific genre or style in mind? You can listen to their sample songs
              and select the artist that fits you best.
            </p>
          </div>
          <div className={styles.textBox}>
            <div className={styles.heading}>
              <div id={styles.num}>{3}</div>
              <h3>This time it’s personal</h3>
            </div>
            <p>
              With our prompts, suggestions, and sample ideas, you'll have all the tools you need to
              share those personal stories and details with our artists to create the ultimate
              personalised song.
            </p>
          </div>
          <div className={styles.textBox}>
            <div className={styles.heading}>
              <div id={styles.num}>{4}</div>
              <h3>Your professional will get started right away</h3>
            </div>
            <p>
              The artist you choose will dedicate their heart and soul to crafting and recording
              your unique custom song within 5 business days or less. We will always keep you in the
              loop and make sure you are 100% over the moon with your song!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HowItWorks;
