import React from "react";
import styles from "./Faq.module.css";

const questions = [
  {
    id: 1,
    question: "How do I request a custom song?",
    answer:
      "Select the artist’s profile you like the sound of by clicking their picture and fill out all fields. If you are ordering an acrylic photo keepsake, don’t forget to upload your image in the box above the “continue” button. Once you get to our payment screen, fill out your details and proceed to payment. You’ll get an on screen confirmation plus an email receipt which instructs you about your forthcoming custom song.",
  },
  {
    id: 2,
    question: "Can I make changes to my custom song once I hear it?",
    answer:
      "Absolutely! Once you’ve listened to it you have a couple of days to let us know if you’d like any of the lyrics changing. We will get those revisions straight over to your artist who will re-record your song and you’ll have your revised song within 48hrs (in most cases 24hrs!)",
  },
  {
    id: 3,
    question: "How will I receive my custom song?",
    answer:
      "When your custom song is ready one of the OSR team will send it to you via email and Whatsapp - so we make sure you receive it as soon as it’s ready! You will be able to save it and/or download it to keep forever.",
  },
  {
    id: 4,
    question: "How long will my custom song take to be delivered?",
    answer:
      "We allow our artists up to 5 business days to fulfill your custom song request so you will receive it within a week of playing your order. We will of course keep you updated and usually deliver it within 3 business days.",
  },
  {
    id: 5,
    question: "How can I contact you?",
    answer:
      "Email us at hello@oursongrequest.com or Whatsapp us on +44 330 043 1781 with any questions or queries and we’ll get back to you within the hour.",
  },
  {
    id: 6,
    question: "Can I share my custom song on social media?",
    answer:
      "Absolutely! You have a lifetime license to share your song in any venue and on any social media platform, it’s a great way to share your family’s story with loved ones and friends.",
  },

  {
    id: 7,
    question: "Which payment methods are accepted?",
    answer: "We accept all major credit and debit cards, Paypal, Apple Pay, Google pay and Klarna.",
  },
];

export default function FAQ() {
  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>How can we help you?</h2>
      <p className={styles.faqText}>
        As you will soon find out once you’ve placed your custom song order, we are very hands on to
        make sure everything goes smoothly and you are delighted with your custom song and
        experience. This is our promise to you.
      </p>
      <section className={styles.faq}>
        {questions.map((item) => (
          <Question question={item.question} answer={item.answer} />
        ))}
      </section>
    </div>
  );
}

const Question = (props) => {
  const [isActive, setActive] = React.useState(false);
  const handleClick = (id) => {
    setActive(!isActive);
  };
  return (
    <div className={styles.questionWrapper}>
      <div className={styles.question} id={props.id}>
        <h3>{props.question}</h3>
        <button onClick={() => handleClick(props.id)}>
          <svg
            className={isActive && styles.active}
            viewBox="0 0 320 512"
            width="100"
            title="angle-down"
          >
            <path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z" />
          </svg>
        </button>
      </div>
      <div className={isActive ? `${styles.answer} ${styles.active}` : styles.answer}>
        {props.answer}
      </div>
    </div>
  );
};
